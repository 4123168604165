import { createUseStyles } from "react-jss"

export const useToasterStyle = createUseStyles((theme) => ({
  root: {
    top: "2em",
    padding: 0,
    margin: 0,
    width: 490,
    zIndex: theme.zIndex.toastNotification,
    "& .Toastify__toast": {
      width: 490,
      border: `1px solid ${theme.palette.border.divider}`,
      borderRadius: 6,
      padding: 0,
      display: "flex",
      justifyContent: "normal",
      height: "auto",
      minHeight: "auto",
      backgroundColor: theme.palette.background.surfaceElevation1,
    },
    "& .Toastify__toast-container": {
      width: 525,
      height: "auto",
    },
    "& .Toastify__progress-bar": {
      background: theme.palette.background.accentDefault,
    },
  },

  title: {
    fontSize: 16,
  },
  message: {
    fontSize: 14,
  },
}))
